import React, {Component} from "react";

const techs = [
    {id:1, title:'McMenamins', list:"ActionScript 3,Adobe Flash CS4,Adobe Photoshop CS4,Object-Oriented Programming"},
    {id:2, title:'Copious Creative Website', list:"HTML/CSS,jQuery,Flash,XML"},
    {id:3, title:'Business Oregon', list:"Flash CS4,Actionscript 3.0,Object-Oriented Programming,XML"},
    {id:4, title:'Rob Salkowitz', list:"HTML/CSS,Javascript,Wordpress"},
    {id:5, title:'Comment Marker System', list:"HTML/CSS,Javascript/jQuery,PHP,AJAX,XML"},
    {id:6, title:'Encompass Institute', list:"XHTML,CSS,Illustrator CS3"}
];

class TechList extends React.Component {
    constructor(props){
        super(props);

        this.techlist = [];
        this.setTechList = this.setTechList.bind(this);
        this.createTechItems = this.createTechItems.bind(this);
    }

setTechList() {
    const tl = techs.forEach( tech => {
        if(this.props.id == tech.id) {
            this.techlist = tech.list.split(',');
        }
      });
}
createTechItems(item) {
    return <li key={item.key}>{item.text}</li>
}
createTechList(item) {
    return <li key={item}><span>{item}</span></li>
}
  render() {
    this.setTechList();
    const listItems = this.techlist.map(this.createTechList);


    return (
        <div className="flex-side">
            <h3>Tech used:</h3>
            <ul className="tech">{listItems}</ul>
        </div>
    )
  }
}

export default TechList;