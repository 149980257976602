import React, {Component} from "react";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

class ScrollBtn extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            direction: this.props.direction
        };
    }
    componentDidMount() {
        this.state.direction = this.props.direction;
    }

  render() {
    let currentIcon = <ExpandMoreIcon />
    this.state.direction = this.props.direction;

    if (this.props.direction === "down") {
      currentIcon = <ExpandMoreIcon />
    }else{
      currentIcon = <ExpandLessIcon />
    }

    return (
      <div
        className={ `scroll-btn ${this.state.direction}` }
        id={this.props.direction}
        onClick={ this.props.clickFunction }>
          {currentIcon}
      </div>
    )
  }
}


export default ScrollBtn;