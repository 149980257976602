import React, {Component} from "react";
import Slider from './Slider';
import TechList from './TechList';
import { NavLink, BrowserRouter as Router, Route } from "react-router-dom";

const projects = [
    {id:1, title:'McMenamins', description:"Explore the McMenamins world with this interactive tower. Not only does it contain pan and zoom functionality, but turn day into night. On hovering over specific McMenamins, a tooltip pops up with location and contact information. With all my AS3 projects, I took an OOP approach in the development process."},
    {id:2, title:'Copious Creative Website', description:"Took a provided re-design and developed the current Copious Creative website within their custom CMS system. In addition to developing the CSS, I configured the flash carousel on the home page as well as jQuery carousels for each project page. Implemented item filtering functionality to the portfolio page, which provides a unique and interactive way to filter Copious' various projects."},
    {id:3, title:'Business Oregon', description:"To add some interactivity to the new Business Oregon website, I created an interactive map which helps users visually find information regarding business development officers across Oregon. In addition to the map, I developed a desktop AIR application that connects to an RSS feed to update people on the latest Business Oregon news and events. The AIR app also comes with useful tools like a dynamic 2 month calendar and clocks with 4 different time zones."},
    {id:5, title:'Comment Marker System', description:"Copious Creative needed a way for clients to comment on design mock-ups during presentations. I developed a simple Facebook-like marker comment system. Because this application was intended to be light, all the markers are saved to a XML file instead of needing a database."},
    {id:6, title:'Encompass Institute', description:"Participating with an awesome team of designers and developers, we redesigned their website with the institute's target audience in mind.I was apart of the front-end design and management of content."}
];
/*{id:4, title:'Rob Salkowitz', description:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed id velit eros. Pellentesque ac dolor a mi suscipit faucibus. Aenean viverra laoreet sagittis. Duis quis felis quis nulla eleifend ullamcorper sit amet a nisi."},*/

class Project extends Component {
    constructor(props){
        super(props);
        this.title = '';
        this.description = '';

    }
    render() {
        const content = projects.forEach( project => {
            if(this.props.match.params.id == project.id) {
                this.title = project.title;
                this.description = project.description;
            }
        });
        return (
            <div>
                <h1>{this.title}</h1>
                <Slider id={this.props.match.params.id} />
                <div className="container">
                    <div className="flex-main">
                        <p>{this.description}</p>
                    </div>
                    <TechList id={this.props.match.params.id} />
                </div>
            </div>
        )
    }
    
}

export default Project;