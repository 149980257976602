import React, {Component} from "react";
import Slide from './Slide';
import Arrow from "./Arrow";


const projectImgs = [
    {id:1, images: ["./images/mcmenamins/full/one.jpg","./images/mcmenamins/full/two.jpg","./images/mcmenamins/full/three.jpg"]},
    {id:2, images: ["./images/copious/full/home.jpg","./images/copious/full/portfolio.jpg","./images/copious/full/project_page.jpg","./images/copious/full/services.jpg"]},
    {id:3, images: ["./images/businessOregon/full/one.jpg","./images/businessOregon/full/two.jpg"]},
    {id:4, images: ["./images/mcmenamins/full/one.jpg","./images/mcmenamins/full/two.jpg","./images/mcmenamins/full/three.jpg"]},
    {id:5, images: ["./images/marker/full/one.jpg","./images/marker/full/two.jpg"]},
    {id:6, images: ["./images/encompassInstitute/full/encompassMockupFinal_HiddenNav.jpg","./images/encompassInstitute/full/encompassMockupFinal_openNav.jpg","./images/encompassInstitute/full/EnCompassSiteMap2.jpg"]}
];


class Slider extends Component {
    constructor (props) {
        super(props);

        this.state = {
            currentImageIndex: 0
        };

        this.nextSlide = this.nextSlide.bind(this);
        this.previousSlide = this.previousSlide.bind(this);
        this.images = [];
        this.currentLibrary = 0;

    }

    previousSlide () {
        const lastIndex = this.images.length - 1;
        const { currentImageIndex } = this.state;
        const shouldResetIndex = currentImageIndex === 0;
        const index =  shouldResetIndex ? lastIndex : currentImageIndex - 1;
    
        this.setState({
          currentImageIndex: index
        });
      }
    
    nextSlide () {
        const lastIndex = this.images.length - 1;
        const { currentImageIndex } = this.state;
        const shouldResetIndex = currentImageIndex === lastIndex;
        const index =  shouldResetIndex ? 0 : currentImageIndex + 1;
    
        this.setState({
          currentImageIndex: index
        });
      }

    render() {

      if(this.props.id != this.currentLibrary) {
        this.state.currentImageIndex = 0;

        const content = projectImgs.forEach( projectImg => {
          if(this.props.id == projectImg.id) {
              this.images = projectImg.images;
          }
        });
      }
      this.currentLibrary = this.props.id;

        return (
            <div className="slider">
                <Slide url={ this.images[this.state.currentImageIndex] } />
                <Arrow direction="left" clickFunction={ this.previousSlide } />
                <Arrow direction="right" clickFunction={ this.nextSlide } />
            </div>
        )
    }
    
}

export default Slider;