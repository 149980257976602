import React, {Component} from "react";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

class Arrow extends Component {

  render() {
    var currentIcon = <ChevronRightIcon />

    if (this.props.direction === "right") {
      currentIcon = <ChevronRightIcon />
    }else{
      currentIcon = <ChevronLeftIcon />
    }

    return (
      <div
        className={ `slide-arrow ${this.props.direction}` }
        onClick={ this.props.clickFunction }>
          {currentIcon}
      </div>
    )
  }
}


export default Arrow;