import React, {Component} from "react";
import Divider from '@material-ui/core/Divider';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import CodeIcon from '@material-ui/icons/Code';

class Home extends React.Component {
    render() {
        return (
        <div className="home">
            <div className="home-container">
                <h2>Hello</h2>
                <p>I'm Tim. An interactive designer who implements creative and useable websites and applications.</p>
                <p><ArrowDownwardIcon /></p>
                <p>If you have an upcoming project or idea you would like to discuss, please <a href="mailto:tim.mcgarry83@gmail.com"><strong>contact me</strong></a>.</p>
            </div>
        </div>
        );
    }
}

export default Home;