import React, {Component} from "react";
import Divider from '@material-ui/core/Divider';
import BuildIcon from '@material-ui/icons/Build';
import SchoolIcon from '@material-ui/icons/School';
import ListAltIcon from '@material-ui/icons/ListAlt';
import GetAppIcon from '@material-ui/icons/GetApp';

class About extends React.Component {
    render() {
        return (
        <div className="about-flex">
            <div className="mid-content toolkit">
            <h2><ListAltIcon /> Resume</h2>
            <a className="download" href="/documents/UXDesigner_resume.pdf"><GetAppIcon /> Click here to download</a>
            <h2><BuildIcon /> Toolkit</h2>
                <ul>
                    <li><span>Adobe Photoshop / XD / Illustrator / InDesign</span></li>
                    <li><span>HTML</span></li>
                    <li><span>CSS/SASS</span></li>
                    <li><span>JavaScript / jQuery</span></li>
                    <li><span>React</span></li>
                    <li><span>Microsoft Visio</span></li>
                    <li><span>Zurb Foundation/Bootstrap</span></li>
                    <li><span>Flash/ActionScript 3</span></li>
                    <li><span>Wordpress</span></li>
                    <li><span>SharePoint 2010/2013/Online</span></li>
                </ul>
            <Divider />
            <h2><SchoolIcon /> Education</h2>
            <h3 className='edu'>Art Institute of Portland<br/><span>B.S. in Interactive Media Design</span></h3>
            </div>
            <div className="main-content about">
                <h1>About</h1>
                <img src='/images/headshot.png' alt="tim portrait" />
                
                <p>
                I’m an interactive designer and developer that cultivates great user experiences with websites and applications. I have a keen eye to detail when it comes to how people should connect with an application depending on client’s objectives and message. A team player who shares their passion and knowledge. Web design professional with a BS in Interactive Media Design. 
                </p>
                <p>
                After graduating college at the Art Institute of Portland, I landed a job at Copious Creative interactive agency in Portland, Oregon. Not only was it was an incredible experience, I got to work on some awesome projects too. After Copious Creative, I had an opportunity in Seattle. MediaPlant initially hired me as a junior web developer. During this time, I grew into the role of lead front-end developer. After learning the capabilities of the SharePoint platform through research and development, I was able to successfully apply those skills as I took on some project management duties.
                </p>
                <p>
                Currently, I am in the process of looking for a full-time position around the Portland, Oregon metro area. I am also available for freelance front-end web design and development. Feel free to contact me so we can get started discussing your goals.
                </p>
            </div>
        </div>
        );
    }
}

export default About;
