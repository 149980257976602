import React, { Component } from 'react';
import { NavLink, HashRouter, BrowserRouter as Router, Route } from "react-router-dom";

//Components
import Home from "./Home";
import Interactive from "./Interactive";
import About from "./About";
import MenuButton from "./MenuButton";

//Styles
import HomeIcon from '@material-ui/icons/Home';
import CodeIcon from '@material-ui/icons/Code';
import InfoIcon from '@material-ui/icons/Info';
import EmailIcon from '@material-ui/icons/Email';
import logo from './logo.svg';
import './App.css';




class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visibility: "nav"
    }

    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.currHeight = 100;
  }

  handleMouseDown(event) {
    let navHeight = this.refs.navmenu.clientHeight;
    
    if(navHeight === 100) {
      this.setState({
        visibility: "nav show"
      })
    }else{
      this.setState({
        visibility: "nav"
      })
    }
  }

    render() {
    return (
    <div className="flex-container">   
      <HashRouter>
        <div className={this.state.visibility} ref="navmenu">
          <MenuButton handleMouseDown={this.handleMouseDown} />
          <h1>Tim McGarry<span className="role">&#123; Interactive Designer &#125;</span></h1>
          <ul className="header" ref="menu">
            <li><NavLink exact to="/"><span className="MuiListItemIcon-root"><HomeIcon /></span><span className="MuiListItemText-root">Home</span></NavLink></li>
            <li><NavLink to="/interactive"><span className="MuiListItemIcon-root"><CodeIcon /></span><span className="MuiListItemText-root">Portfolio</span></NavLink></li>
            <li><NavLink to="/about"><span className="MuiListItemIcon-root"><InfoIcon /></span><span className="MuiListItemText-root">About</span></NavLink></li>
            <li><a href="mailto:tim.mcgarry83@gmail.com"><span className="MuiListItemIcon-root"><EmailIcon /></span><span className="MuiListItemText-root">Contact</span></a></li>
          </ul>
          <h3>More information</h3>
          <ul className="socials">
            <li><a href="http://www.linkedin.com/pub/tim-mcgarry/11/296/6b0" target="_blank" rel="noopener noreferrer">LinkedIn</a></li>
            <li><a href="http://www.flickr.com/photos/48571344@N07/" target="_blank" rel="noopener noreferrer">Flickr</a></li>
          </ul>
        </div>
        <div className="content">
          <Route exact path="/" component={Home}/>
          <Route path="/interactive" component={Interactive}/>
          <Route path="/about" component={About}/>
        </div>
      
      </HashRouter>
      </div>
    );
  }
}

export default App;
