import React, {Component} from "react";

const Slide = ({ url }) => {
 const styles = {
     backgroundImage: `url(${url})`,
     backgroundSize: 'cover',
     backgroundPosition: 'left top'
 };

 return (
     <div className="slide" style={styles}></div>
 );
}

export default Slide;