import React, {Component} from "react";
import MenuIcon from '@material-ui/icons/Menu';

class MenuButton extends React.Component {
    render() {
        return (
            <div className="menuBtn" onMouseDown={this.props.handleMouseDown}><MenuIcon /></div>
        )
    }
}
export default MenuButton;