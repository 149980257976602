import React, {Component} from "react";
import { NavLink, Link, HashRouter, BrowserRouter as Router, Route } from "react-router-dom";
import Divider from '@material-ui/core/Divider';
import Project from './Project';
import ProjectThumbs from './ProjectThumbs';
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ScrollBtn from './ScrollBtn';
import CodeIcon from '@material-ui/icons/Code';

const offset = 23;
const iHeight = 236;

class Interactive extends React.Component {
    constructor (props) {
        super(props);
    
    this.state = {
        topVal: 0,
        tHeight: 0,
        intW: 0,
        thumbContainHeight: 0
    }

    this.handleScroll = this.handleScroll.bind(this);
    this.thumb = React.createRef();
    this.scrollbtn = React.createRef();
    this.currentPos = 0;
    this.updateHeight = this.updateHeight.bind(this);
    }


    handleScroll(event) {
        event.preventDefault();
        this.state.intW = this.refs.intcontainer.clientWidth;
        this.state.tHeight = this.thumb.current.refs.card.clientHeight + offset;
        this.state.thumbContainHeight = -this.refs.thumbs.clientHeight;

        let maxHeight = 0;

        if(this.state.intW >= 620) {
            maxHeight = this.state.thumbContainHeight + this.state.tHeight*2;
        }else{
            maxHeight = this.state.thumbContainHeight + this.state.tHeight;
        }

        if(event.currentTarget.classList.contains('up')) {
            if(this.currentPos >= maxHeight) {
                this.currentPos = this.currentPos - this.state.tHeight;
            }
        }else{
            if(this.currentPos !== 0) {
                this.currentPos = this.currentPos + this.state.tHeight;
            }
        }
        this.setState({
            topVal: this.currentPos
        })

    }

    updateHeight(event) {
        let mainHeight = this.refs.mainwork.clientHeight;
        let midHeight = this.refs.midwork.clientHeight;

        this.state.intW = this.refs.intcontainer.clientWidth;
        this.state.tHeight = this.thumb.current.refs.card.clientHeight;
        this.state.thumbContainHeight = -this.refs.thumbs.clientHeight;
        if(this.state.intW >= 620) {
            this.currentPos = 0;
            this.setState({
                topVal: 0
            })
        }
    }

    componentDidMount() {
        console.log("componentDidMount");
        window.addEventListener('resize', this.updateHeight);
        
    }
    componentWillUnmount() {
        console.log("componentWillUnmount");
        window.removeEventListener('resize', this.updateHeight);
    }



    render() {
        return (
        <div className="int-flex" ref="intcontainer">
            <HashRouter>
            <div className="mid-content work" ref="midwork">
            <span className="controls">
                <ScrollBtn direction="up" clickFunction={ this.handleScroll } />
                <ScrollBtn direction="down" clickFunction={ this.handleScroll } />
            </span>
            <h2>Portfolio</h2>
            <Divider />
            <div className="thumb-container">
                <div className="thumbs" ref="thumbs" style={{top: this.state.topVal}}>
            <NavLink to="/interactive/1" >
                <ProjectThumbs projectid="1" ref={this.thumb} />
            </NavLink>
            <Divider />
            <NavLink to="/interactive/2">
                <ProjectThumbs projectid="2" />
            </NavLink>
            <Divider />
            {/*Rob's site<NavLink to="/interactive/4">
                <ProjectThumbs projectid="4" />
            </NavLink>
        <Divider />*/}
            <NavLink to="/interactive/3">
                <ProjectThumbs projectid="3" />
            </NavLink>
            <Divider />
            <NavLink to="/interactive/5">
                <ProjectThumbs projectid="5" />
            </NavLink>
            <Divider />
            <NavLink to="/interactive/6">
                <ProjectThumbs projectid="6" />
            </NavLink>
            </div>
            </div> 
            </div>
            <div className="main-content work" ref="mainwork">
              <Route path={`/interactive/:id`} component={Project} />
            </div>
            </HashRouter>
        </div>
        );
    }
}

export default Interactive;
