import React, {Component} from "react";

const projects = [
    {id:1, title:'McMenamins', imgsrc:"./images/mcmenamins/thumbs/one_thumb.jpg"},
    {id:2, title:'Copious Creative', imgsrc:"./images/copious/thumbs/home.jpg"},
    {id:3, title:'Business Oregon', imgsrc:"./images/businessOregon/thumbs/one.jpg"},
    {id:4, title:'Rob Salkowitz', imgsrc:"./images/mcmenamins/thumbs/one_thumb.jpg"},
    {id:5, title:'Comment Marker System', imgsrc:"./images/marker/thumbs/one.jpg"},
    {id:6, title:'Encompass Institute', imgsrc:"./images/encompassInstitute/thumbs/encompassMockupFinal_HiddenNav_thumb.jpg"}
];

class ProjectThumbs extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            height: 0
        }
        this.title = '';
        this.imgsrc = '';
        this.height = '';
    }
    getHeight(event) {
        this.state.height = this.refs.card.clientHeight;
        console.log("thumbsHeight: "+this.refs.thumbs.clientHeight);
        console.log("thumb tile: "+this.thumb.current.height);
    }
    componentDidMount() {
        console.log("componentDidMount");
        this.state.height = this.refs.card.clientHeight;
        window.addEventListener('resize', this.updateHeight);
    }
    componentWillUnmount() {
        console.log("componentWillUnmount");
        window.removeEventListener('resize', this.updateHeight);
       
    }
    render() {
        const content = projects.forEach( project => {
            if(this.props.projectid == project.id) {
                this.title = project.title;
                this.imgsrc = project.imgsrc;
            }
        });
        
        return (
            <div className="project-card" ref="card">
                <h3>{this.title}</h3>
                <p><img src={this.imgsrc} /></p>
            </div>
        )
    }
    
}

export default ProjectThumbs;